import React, {useEffect, useState} from "react";
import Layout from "../components/layout";
import {GatsbyImage} from "gatsby-plugin-image";
import ReactHtmlParser from 'react-html-parser';

import '../css/_article.scss';
import Share from "../components/share/sharing";
import {isMobile} from "react-device-detect";
import RelatedArticles from "../components/related-articles";

const Article = (props) => {

    const [data, setData] = useState(props.pageContext.article);
    const [relatedArticles, setRelatedArticles] = useState(null);

    const getContent = () => {
        let content = data.content.replace(/(?:\r\n|\r|\n)/g, '');
        content = content.replace(/ aria-level="1"/g, '');
        content = content.replace(/ style="font-weight: 400;"/g, '');
        content = content.replace(/ padding-left:40px/g, '');
        /*content = content.replace(/src="https:\/\/www.youtube.com\/embed/g, "data-src=\"https://www.youtube.com/embed");*/
        content = content.replace(/<td> *<p>/g, "<td>");
        content = content.replace(/<\/p> *<\/td>/g, "</td>");
        return content;
    }

    useEffect(() => {
        getRecommendedPosts();
    }, [])

    const getRecommendedPosts = () => {
        const recommendedPosts = [];

        const slugs = [];
        let posts = [];
        const relatedArticlesObject = props.pageContext.relatedArticles;
        posts = posts
            .concat(relatedArticlesObject.tags10 || [])
            .concat(relatedArticlesObject.tags9 || [])
            .concat(relatedArticlesObject.tags8 || [])
            .concat(relatedArticlesObject.tags7 || [])
            .concat(relatedArticlesObject.tags6 || [])
            .concat(relatedArticlesObject.tags5 || [])
            .concat(relatedArticlesObject.tags4 || [])
            .concat(relatedArticlesObject.tags3 || [])
            .concat(relatedArticlesObject.tags2 || [])
            .concat(relatedArticlesObject.tags1 || [])
            .concat(relatedArticlesObject.sameCategory || [])
            .concat(relatedArticlesObject.random || []);

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                recommendedPosts.push(post);
                slugs.push(post.slug);
            }
        }
        setRelatedArticles(recommendedPosts);
    }

    return <Layout>
        <div className={"wrapper"}>
            <h1 className={"mt-4"}>{data.heading}</h1>
            <GatsbyImage className={"h-[380px] w-full mt-4"} alt={data.heading} image={data.cover.gatsbyImageData}/>
            <p className="font-semibold font-helvetica text-base lg:text-xl font-arial leading-snug mb-0 mt-8">{data.excerpt}</p>
            <div className={"relative"}>
                <div id="article-content" className={"mt-8"}>
                    {ReactHtmlParser(getContent())}
                </div>
                {!isMobile && <div className={"absolute"} style={{top: 0, left: '-65px'}}>
                    <Share/>
                </div>}
            </div>
            {relatedArticles && relatedArticles.length && <div className={"my-8"}>
                <h2 className={"my-4"}>Related Articles</h2>
                <RelatedArticles articles={relatedArticles.slice(0, 3)}/>
            </div>}
        </div>

    </Layout>
}

export default Article;
